<template>
	<div class="extension_home_page">
		<div class="banner">
			<div class="top_holder"></div>
			<div class="banner_body web-center">
				<div class="banner_content">
					<p class="title">开放插件中心</p>
					<p class="desc">共营插件生态</p>
					<p class="info">
						省时高效的插件神器都在这里<br />
						选品、店铺运营、营销推广一键搞定
					</p>
					<div class="lookAll_btn" @click="$router.push('/extension/category')">查看全部插件</div>
				</div>
			</div>
		</div>
		<!-- 分类导航 -->
		<div style="padding-top: 20px">
			<CategoryNav :navData="navData"></CategoryNav>
		</div>
		<div class="page_body web-center">
			<!-- 左侧展示列表 -->
			<div class="left_list">
				<div class="list" v-for="item in navData" :key="item.id">
					<p class="list_title">{{ item.title }}</p>
					<List :listData="item.listData" :column="3"></List>
				</div>
			</div>
			<!-- 右侧排行榜 -->
			<div class="right_rankList">
				<div class="rank_list" style="margin-top: 40px; min-height: 747px">
					<div class="rankList_top">
						<div class="rankList_top_left">
							<span class="txt" :class="{ active: type == 'week' }" @click="changeRankType('week')">周榜</span>
							<span class="txt" :class="{ active: type == 'month' }" @click="changeRankType('month')">月榜</span>
						</div>
						<div class="rankList_top_right" @click="$router.push('/extension/leaderboard')">更多<a-icon type="right" /></div>
					</div>
					<RankList :listData="listData"></RankList>
				</div>
				<div class="rank_list">
					<div class="rankList_top">
						<div class="rankList_top_left">总榜</div>
						<div class="rankList_top_right" @click="$router.push('/extension/leaderboard')">更多<a-icon type="right" /></div>
					</div>
					<RankList :listData="totalListData"></RankList>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { pluginCategory, pluginList, leaderboardList, weekLeaderboardList, monthLeaderboardList } from '@/api/extension.js';
import CategoryNav from '../components/CategoryNav.vue';
import List from '../components/List.vue';
import RankList from '../components/RankList.vue';
export default {
	components: { CategoryNav, List, RankList },
	data() {
		return {
			navData: [],
			listData: [],
			totalListData: [],
			weekListData: [],
			monthListData: [],
			type: 'week',
		};
	},
	created() {
		pluginCategory().then((res) => {
			const data = res.data.data;
			const navData = Object.values(data);
			const promiseArr = navData.map((i) =>
				pluginList({
					category_first_id: i.id,
					pagesize: '6',
					show_install: 0, //1为只展示已安装插件，0为展示所有
				})
			);
			Promise.all(promiseArr).then((res) => {
				res.forEach((item, index) => {
					if (navData[index]) {
						navData[index].listData = item.data.data.list;
					}
				});
				this.navData = navData;
			});
		});
		//获取总榜数据
		leaderboardList({ page: 1, pagesize: 10 }).then((res) => {
			if (res.data.code == 200) {
				this.totalListData = res.data.data.list;
			}
		});
		//获取周榜数据
		weekLeaderboardList({ page: 1, pagesize: 10 }).then((res) => {
			if (res.data.code == 200) {
				this.weekListData = res.data.data;
				this.listData = this.weekListData;
			}
		});
		//获取月榜数据
		monthLeaderboardList({ page: 1, pagesize: 10 }).then((res) => {
			if (res.data.code == 200) {
				this.monthListData = res.data.data;
			}
		});
	},
	methods: {
		changeRankType(type) {
			this.type = type;
			this.listData = type == 'week' ? this.weekListData : this.monthListData;
		},
	},
};
</script>

<style lang="less" scoped>
@import '@/style/mixin.less';
.extension_home_page {
	.banner {
		height: 460px;
		background: url('~@/assets/img/extension/home_banner.png') center/cover;
		.top_holder {
			height: 60px;
			background: rgba(255, 255, 255, 0.3);
		}
		.banner_body {
			height: 400px;
			padding: 60px 70px 0;
			.banner_content {
				.title {
					font-size: 28px;
					font-weight: 800;
					color: #2c354b;
				}
				.desc {
					margin: 9px 0 27px;
					font-size: 54px;
					font-weight: 800;
					color: rgba(16, 20, 51, 0.9);
					line-height: 75px;
				}
				.info {
					margin-bottom: 40px;
					font-size: 16px;
					color: #374567;
					line-height: 26px;
				}
				.lookAll_btn {
					width: 128px;
					height: 40px;
					background: #4c84ff;
					box-shadow: 0px 2px 5px 0px rgba(76, 132, 255, 0.31);
					border-radius: 4px;
					font-size: 16px;
					font-weight: 500;
					color: #ffffff;
					line-height: 40px;
					text-align: center;
					cursor: pointer;
					&:hover {
						background-color: #6f9cff;
					}
				}
			}
		}
	}
	.page_body {
		.flex(@align:flex-start);
		.left_list {
			width: 900px;
			.list {
				.list_title {
					margin: 40px 0 20px;
					font-size: 20px;
					font-weight: 600;
					color: #2c354b;
					line-height: 28px;
				}
			}
		}
		.right_rankList {
			.rank_list {
				margin-bottom: 20px;
				background-color: #fff;
				.rankList_top {
					padding: 20px 24px;
					.flex();
					.rankList_top_left {
						font-size: 18px;
						font-weight: 600;
						color: #2c354b;
						.txt {
							margin-right: 24px;
							font-size: 16px;
							color: #878fa7;
							cursor: pointer;
							&:hover {
								color: #4c84ff;
							}
							&.active {
								font-size: 18px;
								font-weight: 600;
								color: #2c354b;
							}
						}
					}
					.rankList_top_right {
						height: 18px;
						font-size: 13px;
						color: #878fa7;
						line-height: 18px;
						cursor: pointer;
						&:hover {
							color: #4c84ff;
						}
						.more_icon {
							width: 16px;
							height: 16px;
							vertical-align: middle;
						}
					}
				}
			}
		}
	}
}
</style>
