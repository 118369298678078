<template>
  <div>
    <div class="list">
      <div
        class="list_item"
        :style="{
          width: width,
          marginRight: (index + 1) % column == 0 ? '0px' : '20px',
        }"
        v-for="(item, index) in listData"
        @click="$router.push(`/extension/detail?id=${item.id}`)"
        :key="item.id"
      >
        <div class="list_item_top">
          <img :src="item.icon_url" alt="" class="icon" />
          <div class="info">
            <p class="title" :title="item.title">{{ item.title }}</p>
            <!-- <p class="date">{{ item.date }}</p> -->
            <div class="downloads">
              <img src="@/assets/img/extension/fire.png" alt="" class="fire" />
              {{ item.install_num }}
            </div>
          </div>
        </div>
        <p class="list_item_desc" :title="item.subtitle">{{ item.subtitle }}</p>
        <div class="list_item_bottom">
          <img
            src="@/assets/img/extension/google.png"
            alt=""
            class="browser_icon"
          />
          <div
            :class="item.is_install ? 'dispatch_btn' : 'add_btn'"
            @click.stop="addExtension(item)"
          >
            {{ item.is_install ? "分配环境" : "立即添加" }}
          </div>
        </div>
        <!-- 右上角已添加 -->
        <div v-show="item.is_install" class="install_icon"></div>
      </div>
    </div>
    <!-- 添加插件弹窗 -->
    <AddExtension
      :visible="visible"
      :extensionItem="item"
      @close="visible = false"
      @install="install"
    ></AddExtension>
  </div>
</template>

<script>
import AddExtension from "./AddExtension.vue";
export default {
  name: "List",
  components: { AddExtension },
  props: ["listData", "column"],
  data() {
    return {
      row: 1,
      width: "",
      extensionId: "",
      visible: false,
      newListData: [],
      isInstall: false,
      downloadUrl: "",
      guid: "",
      item: {},
    };
  },
  created() {
    const offsetW = (this.column - 1) * 20 + "px";
    this.width = `calc((100% - ${offsetW}) / ${this.column})`;
  },
  methods: {
    addExtension(item) {
      this.item = item;
      this.extensionId = item.id;
      this.visible = true;
      this.isInstall = item.is_install;
      this.downloadUrl = item.download_url;
      this.guid = item.guid;
    },
    install() {
      this.item.is_install = true;
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/style/mixin.less";
.list {
  .flex(@justify:flex-start;@wrap:wrap);
  .list_item {
    position: relative;
    padding: 24px;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 2px 22px 0px rgba(90, 131, 158, 0.17);
    }
    .list_item_top {
      .flex(@justify:flex-start);
      .icon {
        width: 48px;
        height: 48px;
        margin-right: 20px;
      }
      .info {
        overflow: hidden;
        .title {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #374567;
          line-height: 22px;
          .ellipsis();
        }
        .date {
          margin-top: 5px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #878fa7;
          line-height: 17px;
        }
        .downloads {
          color: #ffc12c;
          .fire {
            vertical-align: -2px;
          }
        }
      }
    }
    .list_item_desc {
      margin: 20px 0 24px;
      height: 40px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7882a1;
      line-height: 20px;
      .ellipsis-multi();
    }
    .list_item_bottom {
      .flex();
      .browser_icon {
        width: 16px;
      }
      .add_btn {
        width: 72px;
        height: 28px;
        background: #4c84ff;
        box-shadow: 0px 2px 5px 0px rgba(76, 132, 255, 0.31);
        border-radius: 4px;
        font-size: 12px;
        color: #ffffff;
        line-height: 28px;
        text-align: center;
        &:hover {
          background-color: #6f9cff;
        }
      }
      .dispatch_btn {
        width: 72px;
        height: 28px;
        border-radius: 4px;
        border: 1px solid #4c84ff;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4c84ff;
        line-height: 28px;
        text-align: center;
        &:hover {
          background-color: #4c84ff;
          color: #fff;
        }
      }
    }
    .install_icon {
      position: absolute;
      top: -3px;
      right: -3px;
      width: 70px;
      height: 70px;
      background: url("~@/assets/img/extension/isInstall.png") no-repeat 0 0 /
        contain;
    }
  }
}
</style>