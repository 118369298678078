<template>
  <div class="RankList">
    <div
      class="list_item"
      v-for="(item, index) in listData"
      :key="item.id"
      @click="$router.push(`/extension/detail?id=${item.id}`)"
      :class="[
        index == 0 && rankListKind != 'recommend' ? 'rank1' : '',
        index == 1 && rankListKind != 'recommend' ? 'rank2' : '',
        index == 2 && rankListKind != 'recommend' ? 'rank3' : '',
      ]"
    >
      <img :src="item.icon_url" alt="" class="icon" />
      <div class="info">
        <p class="title" :title="item.title">{{ item.title }}</p>
        <p class="desc" :title="item.subtitle">{{ item.subtitle }}</p>
      </div>
      <div class="rank_icon"></div>
    </div>
    <!-- 空白页 -->
    <div class="noResult" v-show="!listData.length">
      <img
        src="@/assets/img/extension/exManage_noResult.png"
        alt=""
        class="img"
      />
      <p class="noResult_title">数据尚未出炉</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "RankList",
  props: ["rankListKind", "listData"], //如果值为recommend，则不需要添加排名1、2、3的icon。移除rank1,rank2,rank3类名
  data() {
    return {};
  },
  created() {},
};
</script>

<style lang="less" scoped>
@import "@/style/mixin.less";
.RankList {
  width: 282px;
  .list_item {
    position: relative;
    padding: 12px 24px;
    background-color: #fff;
    cursor: pointer;
    .flex(@justify:flex-start);
    .rank_icon {
      position: absolute;
      left: 0;
      top: 0;
      width: 40px;
      height: 31px;
    }
    &:hover {
      background: #f6f6f6;
      opacity: 0.98;
    }
    &.rank1 {
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 0%,
        #ffefda 100%
      );
      .rank_icon {
        background: url("~@/assets/img/extension/rank1.png");
      }
    }
    &.rank2 {
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 0%,
        #e0ebff 100%
      );
      .rank_icon {
        background: url("~@/assets/img/extension/rank2.png");
      }
    }
    &.rank3 {
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 0%,
        #fad6d6 100%
      );
      .rank_icon {
        background: url("~@/assets/img/extension/rank3.png");
      }
    }
    .icon {
      width: 44px;
      height: 44px;
      margin-right: 12px;
    }
    .info {
      .title {
        width: 178px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #374567;
        line-height: 22px;
        .ellipsis();
      }
      .desc {
        width: 178px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7882a1;
        line-height: 20px;
        .ellipsis();
      }
    }
  }
  .noResult {
    width: 100%;
    height: 160px;
    .flex(center;center;@direction: column);
    .img {
      width: 280px;
      height: 142px;
    }
    .noResult_title {
      margin: 12px auto;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #374567;
      line-height: 25px;
    }
  }
}
</style>