<template>
  <div class="CategoryNav web-center">
    <div
      class="nav_item"
      v-for="(item, index) in navData"
      :key="item.id"
      @click="$router.push('/extension/category?id=' + item.id)"
    >
      <div class="nav_item_top">
        <div class="left">
          <img :src="listData[index].icon" alt="" class="img" />
        </div>
        <div class="right">
          <p class="title">{{ item.title }}</p>
          <p class="desc">{{ listData[index].desc }}</p>
        </div>
      </div>
      <div class="nav_item_lower">
        <p class="title">{{ item.title }}</p>
        <p class="desc">{{ listData[index].desc }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { pluginCategory, pluginList, pluginInfo } from "@/api/extension.js";
import nav_img1 from "@/assets/img/extension/nav_img1.png";
import nav_img2 from "@/assets/img/extension/nav_img2.png";
import nav_img3 from "@/assets/img/extension/nav_img3.png";
import nav_img4 from "@/assets/img/extension/nav_img4.png";
export default {
  name: "CategoryNav",
  props: ["navData"],
  data() {
    return {
      listData: [
        { icon: nav_img1, desc: "帮助了解分析产品，支持海量产品数据查询" },
        { icon: nav_img2, desc: "高效运营，提炼关键词、评论等重要数据" },
        { icon: nav_img3, desc: "查销量、看趋势，轻松掌握店铺数据" },
        { icon: nav_img4, desc: "营销神器，精准锁定市场目标客户" },
        { icon: nav_img1, desc: "内含多种功能丰富的工具，等你来发现" },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
@import "@/style/mixin.less";
.CategoryNav {
  .flex(@wrap:wrap);
  .nav_item {
    width: 285px;
    margin-bottom: 20px;
    cursor: pointer;
    .nav_item_top {
      width: 285px;
      height: 120px;
      padding: 24px;
      background-color: #fff;
      border-radius: 4px;
      .flex(@justify:flex-start;);
      .left {
        width: 60px;
        height: 60px;
        margin-right: 16px;
        .img {
          width: 100%;
        }
      }
      .right {
        .title {
          margin-bottom: 5px;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #2c354b;
          line-height: 25px;
        }
        .desc {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #878fa7;
          line-height: 20px;
          .ellipsis-multi();
        }
      }
    }
    .nav_item_lower {
      display: none;
      width: 285px;
      height: 120px;
      padding: 14px 22px;
      background: #4c84ff;
      box-shadow: 0px 3px 10px 0px rgba(76, 132, 255, 0.51);
      border-radius: 4px;
      color: #ffffff;
      .title {
        font-size: 16px;
        margin-bottom: 6px;
        font-weight: 600;
      }
      .desc {
        font-size: 13px;
        color: #ffffff;
        line-height: 21px;
        .ellipsis-multi(3);
      }
    }
    &:hover {
      .nav_item_top {
        display: none;
      }
      .nav_item_lower {
        display: block;
      }
    }
  }
}
</style>